import { render, staticRenderFns } from "./ecTrialjob.vue?vue&type=template&id=2824e130&scoped=true&"
import script from "./ecTrialjob.vue?vue&type=script&lang=js&"
export * from "./ecTrialjob.vue?vue&type=script&lang=js&"
import style0 from "./ecTrialjob.vue?vue&type=style&index=0&id=2824e130&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2824e130",
  null
  
)

export default component.exports